import React from 'react'

const SimpleFooter = () => {


  return (

	<div>
    
  </div>

  )
}

export default SimpleFooter