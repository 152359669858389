import React from 'react'

const Loader = () => {
  return (
 
      
 <div className="loader-center"> <div className="  spinner-border text-dark" role="status">
         </div></div>
 
  )
}

export default Loader
